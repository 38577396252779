.summary-card-shadow-div {
  background: #fff;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
  min-height: 80vh;
}

.match-score-btn-div .tab-btn-active {
  border-bottom: 3px solid var(--theme-dark-color);
  color: var(--theme-dark-color);
  font-weight: bold;
}

.match-score-btn-div .tab-btn-live {
  padding: 10px;
}

.match-score-btn-div table td {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgb(233, 233, 233);
  border-top: 1px solid rgb(233, 233, 233);
  background-color: aliceblue;
}

.match-score-btn-div table td:hover {
  color: rgb(45, 143, 235);
}
