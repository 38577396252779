.home-footer-div {
  background-color: var(--theme-dark-color);
}

@media only screen and (max-width: 440px) {
  .footer-ul-copy {
    /* padding-bottom: 60px !important; */
  }
}

.home-footer-div .footer-ul {
  list-style: none;
}

.home-footer-div .footer-ul li a {
  text-decoration: none;
  color: #fff;
}
