.table_outer_div_match {
  box-shadow: 1px 1px 6px var(--theme-dark-color);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0px;
  margin: 5px 0;
  /* overflow-x: auto; */
}

.table_outer_div_match .rdt_TableHeader {
  color: white;
  background-color: var(--theme-dark-color);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 17px;
}

.table_outer_div_match .rdt_Pagination {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.table_outer_div_match .header-txt {
  padding: 10px 10px 10px 10px;
  color: white;
  background-color: var(--theme-dark-color);
  letter-spacing: 0.4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 17px;
}

.pointer-set {
  cursor: pointer;
}
