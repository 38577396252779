:root {
  --main-color: #046fa0;
  --theme-dark-color: #004464;
  --theme-light-blue: #62c5f3;
  --theme-dark-red: #792424;
}

.default-list li {
  padding: 4px;
}

.default-list li i {
  color: #113e6d;
}

.default-list {
  list-style: none;
}

@media only screen and (min-width: 440px) {
  .default-list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    list-style: none;
  }
}

@media only screen and (max-width: 440px) {
  .default-list {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    list-style: none;
  }
}

.button-submit-style {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  border-radius: 2px;
  padding: 7px 10px;
  margin: 0 5px;
  cursor: pointer;
  min-width: 250px;
}

.button-submit-style svg {
  font-size: 20px;
}

.button-submit-style:hover {
  background: var(--main-color);
  border: 1px solid #ffffff;
  color: white;
}

.button-submit-style-active {
  background: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
}

.button-submit-style-disable {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 2px;
  padding: 10px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: var(--main-color);
  pointer-events: none;
  cursor: default;
}

.center {
  text-align: center;
}

/* form steps */
.step-img-icon {
  margin: 30px auto;
  width: 80px;
}

.step-button {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  border-radius: 2px;
  padding: 7px 10px;
  margin: 0 5px;
  cursor: pointer;
  min-width: 300px;
}

.step-button:hover {
  background: var(--main-color);
  border: 1px solid #ffffff;
  color: white;
}

.step-button-active {
  background: var(--main-color);
  border: 1px solid var(--main-color);
  color: white;
}

.step-button:disabled {
  text-align: center;
  background: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 2px;
  padding: 10px 10px;
  margin: 0 5px;
  color: var(--main-color);
  pointer-events: none;
  cursor: default;
}

.phone-number input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.live-score-div {
  background-color: var(--main-color);
}

.table_outer_div {
  box-shadow: 1px 1px 6px var(--theme-dark-color);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 10px;
  margin: 5px 0;
}

.table_outer_div .rdt_TableHeader {
  color: white;
  background-color: var(--theme-dark-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 17px;
}

.table_outer_div .rdt_Pagination {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table_outer_div .header-txt {
  padding: 10px 10px 10px 10px;
  color: white;
  background-color: var(--theme-dark-color);
  letter-spacing: 0.4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 17px;
}

.table td {
  padding: 0.75rem 10px;
}

.table_outer_div table {
  padding: 10px;
}

.table_outer_div .table-footer {
  padding-bottom: 10px;
  padding-left: 10px;
}
